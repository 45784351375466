.page-programma header {
    height: 650px;
}

.page-programma .header-content {
    padding-top: 240px;
}

.page-programma section {
    padding-bottom: 120px;
    background-color: var(--blue-dark);
}

.page-programma section .container {
    margin-top: -140px;
    position: relative;
}