nav {
    width: 100%;
    max-width: 100vw;
    height: 120px;
    position: fixed;
    z-index: 2;
}

nav::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00296b",endColorstr="#00296b",GradientType=1);
    z-index: -1;
    opacity: 0.6;
}

nav .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

nav .burger {
    display: none;
    width: 32px;
    height: 20px;
    z-index: 9;
    cursor: pointer;
    position: relative;
}

nav .burger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--white);
    transition: .35s all ease-out;
}

/* ------- Burger menu ------- */

nav .burger span:first-child {
    top: 0;
    transform-origin: top left;
}

nav .burger span:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
}

nav .burger span:last-child {
    bottom: 0;
    transform-origin: top left;
}

/* ------- Burger menu open ------- */

nav .burger.menu-open span:first-child {
    transform: translate(1px, -2px) rotate(45deg);
}

nav .burger.menu-open span:nth-child(2) {
    transform: translateY(-50%) translateX(-10px);
    opacity: 0;
}

nav .burger.menu-open span:last-child {
    transform: translate(-1px, 2px) rotate(-45deg);
}

/* ---------------------------- */

nav .logo {
    display: flex;
    align-items: center;
}

nav .logo img {
    width: 60px;
    margin-right: 16px;
}

nav .logo span {
    font-family: "Bebas Neue", sans-serif;
    color: var(--white);
    font-size: 2.4em;
    text-transform: uppercase;
    font-weight: 700;
    font-style: italic;
}

nav ul {
    display: flex;
    align-items: center;
}

nav ul li {
    list-style-type: none;
    margin-left: 16px;
}

nav ul li a {
    color: var(--white);
    padding: 10px 16px;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 1.5em;
}

nav ul li a span {
    padding-bottom: 6px;
    border-bottom: solid 2px transparent;
    transition: .15s border-bottom ease-out;
}

nav ul li a.active span,
nav ul li a:hover span {
    border-bottom: solid 2px var(--yellow);
}

/* --------------------- Media queries --------------------- */

@media only screen and (max-width: 992px) {

    nav .burger {
        display: flex;
    }

    nav .logo {
        position: relative;
        z-index: 9;
    }

    nav .menu {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        height: 100dvh;
        width: 100vw;
        background-color: var(--blue);
        top: 0;
        left: 0;
        padding: 152px 20px;
        display: flex;
        align-items: center;
        transition: .35s all ease-out;
        overflow: hidden;
    }

    nav .menu::after {
        content: "";
        display: block;
        position: absolute;
        width: 200%;
        height: 200%;
        background-color: var(--blue-dark);
        left: 42%;
        top: 0;
        transform: rotate(10deg);
    }

    nav .menu.menu-open {
        visibility: visible;
        opacity: 1;
    }

    nav ul {
        flex-direction: column;
        align-items: start;
        text-align: left;
        z-index: 1;
    }

    nav ul li {
        margin-left: 0;
        margin-bottom: 32px;
    }

    nav ul li:last-of-type {
        margin-bottom: 0px;
    }

    nav ul li a {
        font-size: 4em;
    }

    nav ul li a span {
        padding-bottom: 0px;
    }

    nav ul li a.active span {
        border-bottom: solid 4px var(--yellow);
    }

}

@media only screen and (max-width: 768px) {

    nav {
        height: 92px;
    }

    nav .logo img {
        width: 50px;
    }

    nav .logo span {
        font-size: 1.8em;
    }

}