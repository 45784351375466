.carousel {
    overflow-x: hidden;
    padding-bottom: 40px;
}

.carousel-inner {
    display: flex;
    width: max-content;
}

.marquee {
    --gap: 20px;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
  }

.track {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    min-width: 100%;
    gap: var(--gap);
    animation: scroll 160s infinite linear;
}

@keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - var(--gap)));
    }
  }
  

.item {
    height: max-content;
    width: 30vw;
    max-width: 400px;
    position: relative;
    background-size: cover;
    background-position: center;
    background-color: var(--blue-dark);
    border: solid 1px var(--blue-2);
    padding: 20px;
}

.item .player-image img {
    width: 100%;
    aspect-ratio: 3/4;
    object-fit: cover;
    margin-bottom: 16px;
}

.item .item-inner {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.item .item-inner::after {
    content: "";
    display: block;
    position: absolute;
    width: 102%;
    height: 200px;
    left: -1%;
    bottom: 0;
    background: rgb(0,41,107);
    background: -moz-linear-gradient(0deg, rgba(0,41,107,1) 0%, rgba(0,41,107,0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(0,41,107,1) 0%, rgba(0,41,107,0) 100%);
    background: linear-gradient(0deg, rgba(0,41,107,1) 0%, rgba(0,41,107,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00296b",endColorstr="#00296b",GradientType=1);
}

.item .item-inner .name {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 1.8em;
    position: relative;
    z-index: 1;
    color: var(--white);
    margin-right: 12px;
}

.item .item-inner .nickname {
    font-style: italic;
    position: relative;
    z-index: 1;
    color: var(--text-on-dark);
}

@media only screen and (max-width: 992px) {

    .item {
        width: 40vw;
    }

}

@media only screen and (max-width: 768px) {

    .item {
        width: 80vw;
    }

}