.page-selectie header {
    height: 650px;
}

.page-selectie .header-content {
    padding-top: 240px;
}

.page-selectie section {
    padding-bottom: 120px;
    background-color: var(--blue-dark);
}

.page-selectie h2 {
    margin-bottom: 24px;
}

.staff .container {
    margin-top: -140px;
    position: relative;
}

.page-selectie .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.page-selectie .item {
    width: auto;
    height: auto;
}

.page-selectie .item-inner .name {
    line-height: 1;
}

.page-selectie .position {
    text-transform: capitalize;
    position: relative;
    color: var(--blue-2);
}

@media only screen and (max-width: 1200px) {

    .page-selectie .grid {
        grid-template-columns: repeat(3, 1fr);
    } 

    .page-selectie .item-inner .name {
        font-size: 1.4em;
    }

    .page-selectie .item-inner .nickname {
        font-size: 1em;
    }

}

@media only screen and (max-width: 768px) {

    .page-selectie .item {
        padding: 10px;
    }

    .page-selectie .grid {
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
    }

    .page-selectie .item-inner .name {
        font-size: 2.2em;
    }

    .page-selectie .item-inner .nickname {
        font-size: 1.4em;
    }

    .page-selectie .position {
        font-size: 1.4em;
    }

}