.page-gallerij header {
    height: 650px;
}

.page-gallerij .header-content {
    padding-top: 240px;
}

.page-gallerij section {
    padding-bottom: 120px;
    background-color: var(--blue-dark);
}

.page-gallerij section .container {
    margin-top: -140px;
    position: relative;
}