.programma {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--white);
    flex-wrap: wrap;
}

.programma .card {
    width: calc(50% - 10px);
    padding: 52px 32px;
    border: solid 1px var(--blue-2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.programma .card-team {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 35%;
}

.programma .card-team img {
    width: auto;
    object-fit: contain;
    height: 120px;
}

.programma .card-team p {
    text-align: center;
    margin-top: 16px;
    font-size: 1em;
    color: var(--text-on-dark);
}

.programma .card-datetime {
    text-align: center;
}

.programma .card-datetime .time {
    font-family: "Bebas Neue", sans-serif;
    font-size: 2.6em;
    color: var(--white);
}

.programma .card-datetime .date {
    color: var(--text-on-dark);
    margin-top: -8px;
}

/* --------------------- Media queries --------------------- */

@media only screen and (max-width: 992px) {

    .programma .card {
        width: 100%;
    }

}

@media only screen and (max-width: 768px) {

    .programma .card {
        padding: 32px 16px;
    }

    .programma .card-team img {
        height: 100px;
    }

    .programma .card-team p {
        display: none;
    }

    .programma .card-datetime .time {
        font-size: 2.2em;
    }

}

@media only screen and (max-width: 400px) {

    .programma .card-team img {
        height: 80px;
    }

    .programma .card-team p {
        display: none;
    }

    .programma .card-datetime .time {
        font-size: 2.2em;
    }

}