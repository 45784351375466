/* @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --text: #000000;
    --black: #000000;
    --white: #ffffff;
    --gray: #f1f1f1;
    --blue: #00296B;
    --blue-2: #1c54af;
    --blue-dark: #002157;
    --yellow: #ffe900;

    --text: #1b1b1b;
    --text-on-dark: #e0e0e0; 

    --shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: "Roboto", sans-serif;
    background-color: var(--white);
    max-width: 100vw;
    overflow-x: hidden;
}

.container {
    margin: 0 auto;
    max-width: 1400px;
    width: 100%;
    padding: 0px 20px;
}

.cookie-consent {
    position: fixed;
    width: 100%;
    z-index: 999;
    border-top: solid 1px var(--blue-2);
    background-color: rgba(0, 41, 107, 0.8);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    padding: 24px;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cookie-consent p {
    font-size: 1em;
}

.cookie-btn-wrapper {
    display: flex;
}

.cookie-btn-wrapper .btn {
    border: none;
    cursor: pointer;
}

.cookie-btn-wrapper .btn.cookie-decline {
    background: none;
    color: var(--gray);
}

.cookie-btn-wrapper .btn.cookie-accept {
    color: var(--black);
}

.p-normal {
    padding: 240px 0px;
}

h1, h2, h3 {
    font-family: "Bebas Neue", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 1.2em;
    color: var(--white);
}

h1 {
    font-size: 3.5em;
}

h2 {
    font-size: 3em;
}

h3 {
    font-size: 2em;
}

a {
    text-decoration: none;
    color: var(--text);
}

a:not(p a) {
    font-size: 1.2em;
}

p a {
    border-bottom: solid 1px var(--white);
}

p a:hover {
    color: var(--yellow) !important;
    border-bottom: solid 1px var(--yellow);
}

p {
    color: var(--text-on-dark);
    font-size: 1.3em;
    line-height: 1.5em;
}

.btn {
    background-color: var(--yellow);
    padding: 10px 24px;
    font-family: "Bebas Neue", sans-serif;
    text-transform: uppercase;
    font-size: 1.6em;
    display: block;
    width: max-content;
    transform: skewX(-10deg);
    margin-left: 8px;
    transition: .15s padding ease-out;
    border-bottom: none;
}

.btn-secondary {
    background-color: var(--gray);
}

.btn-icon {
    display: flex;
    align-items: center;
}

.btn-icon svg {
    width: 20px;
    transform: skewX(10deg);
    margin: -5px 0px 0px 8px;
}

.btn:hover {
    padding: 10px 40px;
}

.btn span {
    display: block;
    transform: skewX(10deg);
}


.button-row {
    display: flex;
}

.button-row .btn {
    margin-right: 12px;
}

.loading {
    fill: var(--yellow);
    animation: loading 1s ease-in-out 0s infinite;
}

@keyframes loading {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* --------------------- Media queries --------------------- */

@media only screen and (max-width: 992px) {

    .container {
        padding: 0px 20px;
    }

    .p-normal {
        padding: 200px 0px;
    }

}

@media only screen and (max-width: 768px) {

    .cookie-consent {
        padding: 18px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    
    .cookie-consent p {
        margin-bottom: 16px;
        display: block;
        text-align: center;
    }

    h1 {
        font-size: 2.8em;
    }

    h2 {
        font-size: 2.4em;
    }

    p {
        font-size: 1.2em;
    }

    .btn {
        font-size: 1.2em;
    }

}

@media only screen and (max-width: 500px) {
    
    h1 {
        font-size: 2em;
    }

    h2 {
        font-size: 2em;
    }

    p {
        font-size: 1em;
    }

}