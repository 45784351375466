/* --------------------- Header --------------------- */

header {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-color: var(--blue);
    height: 950px;
    padding-bottom: 100px;
}

header .container {
    position: relative;
    height: 100%;
}

header::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: var(--blue);
    opacity: 0.8;
}

.header-image {
    width: 55%;
    height: calc(100% - 40px);
    background-size: cover;
    background-position: center; 
    position: absolute;
    right: 0;
    top: 120px;
    overflow: hidden;
}

.header-image img {
    width: 100%;
    height: auto;
    opacity: 0;
    visibility: hidden;
}

.header-content {
    position: relative;
    z-index: 1;
    padding: 300px 0px 80px;
}

.header-content h1.title-mobile {
    display: none;
}

.header-content h1 {
    color: var(--white);
    text-align: center;
}

.header-content h1 span {
    display: block;
    font-style: italic;
    font-size: 1.8em;
    margin: 18px 0px 0px -14px;
    color: transparent;
    -webkit-text-stroke: 1px var(--white);
}

.header-items {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    height: 240px;
    width: 75%;
    border: solid 1px var(--blue-2);
    z-index: 1;
}

.header-items > div {
    width: 60%;
    height: 100%;
    backdrop-filter: blur(4px);
    padding: 32px 32px;
}

.header-items > div:first-child {
    border-right: solid 1px var(--blue-2);
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.header-items > div:first-child > div {
    text-align: center;
}

.header-items > div:first-child h3 {
    margin-bottom: 24px;
}

.header-items .upcoming {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--white);
}

.header-items .upcoming .card-team {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 35%;
}

.header-items .upcoming .card-team img {
    width: auto;
    object-fit: contain;
    height: 120px;
}

.header-items .upcoming .card-team p {
    text-align: center;
    margin-top: 16px;
    font-size: 1em;
    color: var(--text-on-dark);
}

.header-items .upcoming .card-datetime {
    text-align: center;
}

.header-items .upcoming .card-datetime .time {
    font-family: "Bebas Neue", sans-serif;
    font-size: 2.6em;
    color: var(--white);
}

.header-items .upcoming .card-datetime .date {
    color: var(--text-on-dark);
    margin-top: -8px;
}

header .transition {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background: rgb(0,33,87);
    background: -moz-linear-gradient(0deg, rgba(0,33,87,1) 0%, rgba(0,33,87,0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(0,33,87,1) 0%, rgba(0,33,87,0) 100%);
    background: linear-gradient(0deg, rgba(0,33,87,1) 0%, rgba(0,33,87,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#002157",endColorstr="#002157",GradientType=1);
}

/* --------------------- Section: Info --------------------- */

.info {
    background-color: var(--blue-dark);
    overflow: hidden;
    padding-top: 120px;
}

.info .pictures {
    display: flex;
    justify-content: center;
}

.pictures-wrapper {
    display: flex;
    align-items: center;
}

.pictures-wrapper > div {
    position: relative;
}

.pictures-wrapper > div .picture {
    overflow: hidden;
    width: auto;
    height: auto;
    transform: skewX(-10deg);
    width: 35vw;
    min-width: 500px;
    margin-left: 32px;
}

.pictures-wrapper > div .picture img {
    transform: skewX(10deg);
    width: 120%;
    margin-left: -10%;
}

.pictures-wrapper > div .gallerij {
    position: absolute;
    bottom: -90px;
    right: -40px;
    width: 200px;
    height: 200px;
    z-index: 2;
}

.pictures-wrapper > div .gallerij img {
    width: 100%;
    animation: rotate 12s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.pictures-wrapper > div .gallerij span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--yellow);
    width: 60px;
    height: 60px;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.pictures-wrapper > div .gallerij span svg {
    fill: var(--black);
    width: 32px;
}

/* --------------------- Section: Team --------------------- */

.team {
    background-color: var(--blue-dark);
}

.team .team-header {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.team .team-header .btn {
    margin-left: 32px;
    margin-top: -6px;
}

/* --------------------- Section: Cta --------------------- */

.cta {
    background-color: var(--blue);
}

.cta .container {
    position: relative;
}

.cta-image-wrapper {
    height: calc(100% + 80px);
    width: 40%;
    right: 0;
    top: -40px;
    position: absolute;
    transform: skewX(-10deg);
    overflow: hidden;
}

.cta-image {
    background-size: cover;
    background-position: center;
    width: 124%;
    margin-left: -12%;
    height: 100%;    
    transform: skewX(10deg);
}

.cta-logo {
    display: block;
    width: 600px;
    max-width: 100%;
    margin: 0 auto 80px;
    position: relative;
    z-index: 1;
}

.cta-content {
    padding: 120px 60px 100px 0px;
    width: 54%;
}

.cta-content h2 {
    color: var(--white);
    margin-bottom: 32px;
}

.cta-content p {
    color: var(--text-on-dark);
    margin-bottom: 32px;
}

.cta-content p a {
    color: var(--text-on-dark);
}

/* --------------------- Section: Details (programma + stand) --------------------- */

.details {
    background-color: var(--blue);
    padding: 120px 0px;
    min-height: 800px;
}

.details .loading {
    display: block;
    margin: 200px auto 0px;
    width: 40px;
    height: auto;
}

.details-header {
    display: flex;
    margin-bottom: 40px;
    align-items: center;
    justify-content: space-between;
}

.details-header > div {
    display: flex;
}

.details-header h2 {
    color: var(--white);
    margin-right: 24px;
    opacity: 0.6;
    cursor: pointer;
}

.details-header h2.active {
    opacity: 1;
    border-bottom: solid 4px var(--yellow);
}

.detail {
    display: none;
}

.detail.active {
    display: block;
}

/* --------------------- Section: Verslag --------------------- */

.verslag {
    max-width: 100vw;
    background-color: var(--blue-dark);
    padding-bottom: 80px;
}

.verslag .container {
    display: flex;
}

.verslag .left {
    width: auto;
    margin-right: 80px;
    padding-top: 40px;
}

.verslag .right {
    width: max-content;
}

.verslag h2 {
    margin-bottom: 32px;
}

.verslag .button-row {
    margin-top: 40px;
}

/* --------------------- Media queries --------------------- */

@media only screen and (max-width: 992px) {

    .header-items {
        width: calc(100% - 40px);
    }

    .header-items .upcoming .card-datetime .time {
        font-size: 2.2em;
    }

    .header-items .upcoming .card-team p {
        display: none;
    }

    .cta {
        padding: 0;
    }

    .cta-logo {
        width: 400px;
    }

    .cta-content {
        width: 100%;
        padding-bottom: 40px;
    }

    .cta-image-wrapper {
        position: unset;
        width: calc(100% - 40px);
        height: 500px;
        margin: 0px auto;
        transform: translateY(50px);        
    }

    .cta-image {
        transform: none;
    }

    .verslag .container {
        flex-wrap: wrap;
    }

    .verslag .left {
        width: 100%;
        text-align: center;
        max-width: 700px;
        margin: 0 auto;
    }

    .verslag .left .button-row {
        width: max-content;
        margin: 40px auto 0px;
    }

    .verslag .right {
        max-width: 100%;
        width: max-content;
        margin: 80px auto 0px;
    }

}

@media only screen and (max-width: 768px) {

    header {
        height: max-content;
    }

    header > .container {
        height: 100%;
        min-height: 700px;
    }

    .header-content {
        padding-top: 200px;
    }

    header .header-content h1 span {
        font-size: 2.4em;
        -webkit-text-stroke: 0px transparent;
        color: var(--white);
    }


    .header-content h1.title {
        display: none;
    }    

    .header-content h1.title-mobile {
        display: block;
        font-size: 3em;
    }

    .header-content h1 span {
        font-size: 1.5em;
        margin-top: 4px;
    }

    .header-items > div:first-child {
        display: none;
    }

    .header-items .upcoming {
        width: 100%;
    }


    .header-items .upcoming .card-team img {
        height: 100px;
    }

    .pictures-wrapper > div .picture {
        min-width: 70vw;
        min-height: 400px;
        margin-left: 16px;
    }

    .pictures-wrapper > div .picture img {
        min-height: 400px;
        object-fit: cover;
    }
    
    .details-header .btn {
        display: none;
    }

    .team .team-header {
        display: block;
    }

    .team .team-header .btn {
        margin-left: 0;
        margin-top: 16px;
    }

}

@media only screen and (max-width: 492px) {

    /* .header-content h1 {
        font-size: 2.5em;
    } */

    .header-content h1 span {
        font-size: 1.24em;
        margin-top: 0px;
    }

}