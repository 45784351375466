footer {
    background-color: var(--blue);
    padding: 280px 0px 60px;
    background-position: center 60%;
    background-size: 46%;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
}

footer::after {
    content: "";
    display: block;
    width: 100%;
    height: 240px;
    position: absolute;
    left: 0;
    top: 0;
    background: rgb(0,41,107);
    background: -moz-linear-gradient(0deg, rgba(0,41,107,0) 0%, rgba(0,33,87,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(0,41,107,0) 0%, rgba(0,33,87,1) 100%);
    background: linear-gradient(0deg, rgba(0,41,107,0) 0%, rgba(0,33,87,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00296b",endColorstr="#002157",GradientType=1);
}

footer .logo {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: max-content;
    transform: scale(1.5);
}

footer .logo img {
    width: 60px;
    margin-right: 16px;
}

footer .logo span {
    font-family: "Bebas Neue", sans-serif;
    color: var(--white);
    font-size: 2.4em;
    text-transform: uppercase;
    font-weight: 700;
    font-style: italic;
}

footer .copyright {
    color: var(--text-on-dark);
    font-size: 1em;
    margin: 80px 0;
    text-align: center;
}