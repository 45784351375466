.stand-team {
    width: 100%;
    padding: 16px 4px;
    border-bottom: solid 1px var(--blue-2);
    color: var(--blue-2);
    font-size: 1.3em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.stand-team .team-info,
.stand-team .team-numbers {
    display: flex;
    align-items: center;
}

.stand-team .team-numbers span {
    display: block;
    width: 30px;
    text-align: right;
    margin-left: 12px;
}

.stand-team.own-team {
    color: var(--yellow);
}

.stand-team .team-position {
    display: block;
    width: 20px;
    margin-right: 12px;
    text-align: right;
}

.stand-team .team-name {
    font-weight: 700;
    text-transform: uppercase;
}

@media only screen and (max-width: 768px) {

    .stand-team {
        font-size: 1em;
        padding: 12px 0px;
    }

}